<template>
  <div v-if='isEffect' class="effectBox">
    <img class='effectImg' src='@/assets/img/invalidImg.png'>
    <div class="description">链接已失效</div>
    <div class="description">如需查看请再次分享</div>
  </div>
  <div class="shareFlex" v-else>
    <div class='centerBox'>
      <div class='bottomBox'>
        <img class='tipImg' src='@/assets/img/shield.png' />
        <div class='tipText'>为保证信息安全，请您进行手机号验证</div>
      </div>
      <div class='shareText' style='margin-top: 35px;'>验证分享人手机号</div>
      <div class='shareText'>{{phone.slice(0,3)}}****{{phone.slice(-4)}}</div>
      <div class='shareText1'>每天最多可获取3次验证码</div>
      <div class='codeBox'>
        <van-field
          v-model="smsCode"
          name="smsCode"
          maxlength="4"
          center
          clearable
          type="number"
          label=""
          placeholder="请输入验证码"
          :rules="[{ required: true}]"
        >
          <template #button>
            <div v-if="!visible" @click="getSmsCode()" class='getText'>获取验证码</div>
            <div v-else class='timeText'>{{timeSeconds}}</div>
          </template>
        </van-field>
      </div>
      <div class='confirmButtonColor' @click='confirmBtn'>确认</div>
    </div>
    
  </div>
  
</template>
<script>
  import moment from 'moment';
  import { Dialog,Toast } from 'vant';
  import { verifymsg, msgcodeSv, getifvalid } from '@/api/enterprise'
  import { hidewxOption, getShareData, domainUrl } from '@/lib/utils';
  var timerId
  export default {
  name: 'share',
  components: {
    
  },
  data() {
    return {
      smsCode: '',
      visible: false,
      timeSeconds: '',
      canclick: true,
      isEffect: false,
      pdf_name: '',
      phone: this.$route.query.phone,
      fileName: this.$route.query.fileName,
      createDate: this.$route.query.createDate,
    };
  },
  mounted(){
    getifvalid(this.fileName, this.createDate).then((res)=>{
      if(res.wx_code == '200'){
        if(res.if_valid == 'n'){
          this.isEffect = true
        }
      }
    })
  },
  created(){
    getShareData(window.location.href.split('#')[0])
    //隐藏分享
    hidewxOption()
  },
  methods: {
    //获取验证码
    getSmsCode(){
      if(this.canclick){
        const params = {
          mobile: this.phone,
          msgtype: 'send',
          msgcode: ''
        }
        this.canclick = false
        msgcodeSv(params).then(res =>{
          this.canclick = true
          if(res.msg_status == '0'){
            this.sendMessage(120);
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        })
      }
    },
    sendMessage(second) {
      if(second >= 0) {
        this.visible = true;
        this.timeSeconds = second + "s"
        second--;
        timerId = setTimeout(() => {
          this.sendMessage(second);
        }, 1000);
      } else {
        this.visible = false;
      }
    },
    confirmBtn(){
      if(!this.smsCode){
        Toast({
          message: '请输入验证码',
          duration:'3000'
        });
        return
      }
      msgcodeSv({
        mobile: this.phone,
        msgtype: 'verify',
        msgcode: this.smsCode
      }).then(res =>{
        if(res.msg_status=='0'){
          this.smsCode = ''
          clearTimeout(timerId)
          this.visible = false;
          window.location.href = domainUrl + '/download/sendRecord/'+ this.fileName
        }else{
          Toast({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    }
  }
};
</script> 
<style scoped>
.centerBox{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shareText{
  font-size: 24px;
  color: #333333;
  line-height: 24px;
  margin-bottom: 20px;
}
.shareText1{
  color: #666666;
  font-size: 15px;
  margin-bottom: 35px;
}
.codeBox{
  background-color: #F6F6F6;
  border-radius: 45px;
  height: 45px;
  width: 295px;
  
}
.getText{
  color: #3662EC;
  font-size: 15px;
}
.timeText{
  color: #666666;
  font-size: 15px;
}
.confirmButtonColor{
  margin-top: 35px;
  width: 295px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background-color: #E50012;
  color: #FFFFFF;
  border-radius: 45px;
}
.bottomBox{
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #FFF9ED;
}
.tipImg{
  margin-left: 20px;
  width: 15px;
  height: 15px;
  margin-right: 7px;
}
.tipText{
  color: #FF6010;
  font-size: 15px;
  line-height: 20px;
}
.description{
  color: #333333;
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
}
.effectBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
.effectImg{
  width: 160px;
  height: 180px;
  margin-bottom: 10px;
}
</style>
<style>
.codeBox .van-cell{
  background-color: #F6F6F6;
  border-radius: 45px;
  height: 45px;
}
</style>